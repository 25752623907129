import { CONSTANT_ROUTES, PREFIX_SD } from './constants';
import { encodeMetadata } from './metadata';

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export function getLangWithUrl(slug?: string) {
  // if (i18n.language && i18n.language !== 'vi') return `/${i18n.language}/${slug}`;
  return slug ? `/${slug}` : '/';
}

export function getSdCloudUrl(slug?: string) {
  // if (i18n.language && i18n.language !== 'vi') return `/${i18n.language}/${slug}`;
  return slug ? `${PREFIX_SD}/${slug}` : `${PREFIX_SD}`;
}

export const baseURL = (src?: string): string => `${BASE_URL || ''}${src || ''}`;

// export const mappingURLToExternal = (path?: string) => {
//   if (path && typeof window !== 'undefined') {
//     return new URL(path, typeof window !== "undefined" ? window.location.origin : '').href;
//   }
//   return '';
// };

export function mapModifiers(
  baseClassName: string,
  ...modifiers: (string | string[] | false | undefined)[]
): string {
  return modifiers
    .reduce<string[]>((acc, m) => (!m ? acc : [...acc, ...(typeof m === 'string' ? [m] : m)]), [])
    .map((m) => `-${m}`)
    .reduce<string>(
      (classNames, suffix) => `${classNames} ${baseClassName}${suffix}`,
      baseClassName
    );
}

export function redirectToWorkspaceWithPrompt(meta: any, modelId?: any) {
  const endcodeMeta = encodeMetadata(meta);
  window.open(
    `${window.location.origin}${CONSTANT_ROUTES.PROMPT}?prompt=${encodeURIComponent(
      endcodeMeta
    )}&modelId=${modelId || ''}`,
    '_blank',
    'noopener,noreferrer'
  );
}
