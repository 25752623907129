import { create } from 'zustand';
import { getProfileService } from '~/pages/api/extends/auth';
import { ProfileDataType } from '~/pages/api/extends/auth/types';
import {
  removeAccessToken,
  removeLocalStorage,
  removeRefreshAccessToken,
} from '~/pages/api/extends/common/storage';
import { LOCAL_STORAGE } from '~/utils/constants';

type InitialStateTypes = {
  loading: boolean;
  success: boolean;
  error: boolean;
  profile?: ProfileDataType;
};

type Actions = {
  getProfile: () => void;
  resetAuth: () => void;
};

const initialState: InitialStateTypes = {
  loading: false,
  success: false,
  error: false,
  profile: undefined,
};

export const useAuth = create<InitialStateTypes & Actions>((set) => {
  return {
    ...initialState,
    getProfile: async () => {
      set({ loading: true });
      try {
        const data = await getProfileService();
        set((state) => ({
          ...state,
          profile: data,
        }));
      } catch (error) {
        set({ error: true });
      } finally {
        set({ loading: false });
      }
    },
    resetAuth: async () => {
      set({ loading: true });
      try {
        set((state) => ({
          ...state,
          profile: undefined,
        }));
        removeAccessToken();
        removeRefreshAccessToken();
        removeLocalStorage(LOCAL_STORAGE.PAYMENT);
        removeLocalStorage(LOCAL_STORAGE.INFO_PAYMENT_SESSION);
        removeLocalStorage(LOCAL_STORAGE.USAGE_TIME_SESSION);
        removeLocalStorage(LOCAL_STORAGE.USAGE_TIME_SESSION);
      } catch (error) {
        set({ error: true });
      } finally {
        set({ loading: false });
      }
    },
  };
});
